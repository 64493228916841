<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="pickup === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>

    <b-row
      v-if="pickup"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          v-if="pickup"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <div
            class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0"
          >
            <div style="color: black">
              <div class="logo-wrapper">
                <img
                  src="@/assets/images/logo/logo.png"
                  width="250px"
                  height="100%"
                >
                <h3 class="text-primary invoice-logo" />
              </div>
            </div>
          </div>

          <div class="d-flex flex-row justify-content-between">
            <div class="ml-3">
              <h1 style="color: black; font-size: 30px">
                Pickup N° <span style="color: black; font-size:20px">{{ pickup.id }}</span>
              </h1>
            </div>
            <div>
              <h3
                class="mr-3"
                style="color: black"
              >
                Date:
                <span style="color: black"> {{ formatFn(pickup.created_at) }}</span>
              </h3>
              <h3
                class="mr-3"
                style="color: black"
              >
                Nombre de colis:
                <span
                  v-if="!isLoading"
                  style="color: black"
                > {{ orders.length }}</span>
              </h3>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mr-1"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                class="mb-2 mt-0 ml-2"
                style="border: 2px solid black"
              >
                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h4"
                  style="color:black"
                >
                  <strong>{{ company.name }} :</strong> Société de livraison des colis
                </b-card-text>
                <b-card-text
                  class="ml-2 h4  mr-2 mb-1"
                  style="color:black"
                >
                  <strong>Matricule fiscale :</strong> {{ company.tax_identification_number }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h4"
                  style="color:black"
                >
                  <strong>Adresse :</strong> {{ company.address }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h4"
                  style="color:black;"
                >
                  <strong>Tel :</strong> {{ company.phone1 }}
                </b-card-text>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mr-1"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                class="mb-2 mt-0 ml-2"
                style="border: 2px solid black"
              >
                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h4"
                  style="color:black;"
                >
                  <strong> Fournisseur : </strong> {{ user.username }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h4"
                  style="color:black;"
                >
                  <span class="font-weight-bold"><strong> Matricule fiscale : </strong> {{ user.tax_identification_number }} </span>
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h4"
                  style="color:black;"
                >
                  <strong> Adresse : </strong> {{ user.address }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h4"
                  style="color:black;"
                >
                  <strong> Tel : </strong> {{ user.phone }}
                </b-card-text>
              </div>
            </div>

          </div>
          <div
            v-if="isLoading === true"
            class="text-center mb-2"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
          </div>
          <!-- Invoice Description: Table -->
          <div class="mr-1 ml-1">
            <b-table-lite
              class="ta"
              style="font-size: 20px; border: 2px solid black"
              responsive
              :items="orders"
              :fields="[ 'qrcode','destinataire','gouvernorat','prix','date']"
            >
              <template
                #cell(qrcode)="data"
              >
                <qrcode-vue
                  :value="data.item.qrcode"
                  :size="size"
                  level="H"
                />
                <h5>{{ data.item.qrcode }}</h5>
              </template>
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </div>
          <!-- Invoice Description: Total -->

          <b-row class="mr-1">
            <b-col />

            <b-col v-if="!isLoading">
              <div
                class="mb-4"
                style="font-size: 20px"
              >
                <table style="font-size: 20px; border: 2px solid black">
                  <tr>
                    <th>Total TTC</th>
                    <th>Total</th>
                  </tr>
                  <tr>
                    <td>{{ pickup.total_price_ttc }} DT</td>
                    <td>{{ pickup.total_price_ttc - pickup.total_price_delivery }} DT</td>
                  </tr>
                </table>
              </div>
            </b-col>
          </b-row>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :class="{ 'disabled-cursor': isLoading }"
            :disabled="isLoading"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import storeAuth from '@/store/store'
import QrcodeVue from 'qrcode.vue'

import {
  BRow,
  BCol,
  BCard,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    QrcodeVue,

  },
  data() {
    return {
      orders: [],
      pickup: [],
      user: {},
      company: {},
      size: 40,
      isLoading: false,
    }
  },

  created() {
    this.company = storeAuth.state.company
    this.id = this.$route.params.id
    this.getPickup()
    this.user = storeAuth.state.user
  },
  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    async getPickup() {
      this.isLoading = true
      await axios.get(`/api/pickups/detail/${this.$route.params.id}`).then(response => {
        this.pickup = response.data
        const { orders } = this.pickup
        for (let i = 0; i < orders.length; i += 1) {
          const data = {
            qrcode: (orders[i].id).toString(),
            gouvernorat: orders[i].governorate,
            fournisseur: orders[i].supplier,
            tel: orders[i].phone1,
            destinataire: orders[i].name,
            prix_de_livraison: orders[i].price_delivery,
            adresse: orders[i].address,
            prix: orders[i].price_ttc,
            date: orders[i].date,
          }
          this.orders.push(data)
          this.isLoading = false
        }
      })
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const sommeTTC = 0
    const sommeFrais = 0
    const total = 0
    const tax = 0
    const sommeHT = 0
    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      printInvoice,
      sommeHT,
      tax,
      total,
      sommeTTC,
      sommeFrais,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor{
    background-color:#EA2F2F ;
  }
.contact {
  background-color: #EA2F2F;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  html, body {
    height: 99%;
  }
 .ta {
    overflow-x: visible;
    font-size: 20px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor{
    background-color:#EA2F2F ;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #EA2F2F;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
